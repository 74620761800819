import "../styles/PrivacyPolicy.css";
import Logo from "../assets/logos/Logo.png";

export const PrivacyPolicy = () => {
  return (
    <div className="privacypolicy">
      <div className="privacypolicy__content">
        <div className="privacypolicy__title">
          <h1>Privacy Policy</h1>
          <div className="privacypolicy__title_logo">
            <img src={Logo} alt="logo" />
          </div>
        </div>
        <div className="privacypolicy__description">
          <p>
            This Privacy Policy describes our policies and procedures regarding
            the collection, use, and disclosure of your information when you use
            our service. It also informs you about your privacy rights and how
            the law protects you.
          </p>
          <p>
            We periodically update this Privacy Policy. We will post any privacy
            policy changes on this page and, if the changes are significant, we
            will provide a more prominent notice by adding a notice on the
            Services homepages, login screens, or by sending you an email
            notification. While we will notify you of any material changes to
            this Privacy Policy, we encourage you to review this Privacy Policy
            periodically. We will also keep prior versions of this Privacy
            Policy in an archive for your review.
          </p>
          <p>
            We use your personal data to provide and improve our service. By
            using our service, you agree to the collection and use of
            information in accordance with this Privacy Policy.
          </p>
          <p>
            If you have any questions about this Privacy Policy or our treatment
            of the information you provide us, please write to us by email at
            <a href="mailto:trademasterislive@gmail.com">
              {" "}
              customerservice@trademaster.live
            </a>
            .
          </p>
        </div>
        <div className="privacyPolicy__title">
          <h2>Interpretation and Definitions</h2>
        </div>
        <div className="privacyPolicy__description">
          <h3>Interpretation</h3>
          <p>
            The words that have their initial letter capitalized have specific
            meanings defined under certain conditions. The following definitions
            shall have the same meaning regardless of whether they appear in
            singular or plural.
          </p>
          <h3>Definitions</h3>
          <p>
            For the purposes of this Privacy Policy: - "You" means the
            individual accessing or using the service or the company or other
            legal entity on whose behalf such individual is accessing or using
            the service, as applicable. - "Company" (referred to as "we," "us,"
            or "our" in this agreement) refers to TradeMaster. 
            <br />
            - "Affiliate"
            means an entity that controls, is controlled by, or is under common
            control with a party, where "control" means ownership of 50% or more
            of the shares, equity interest, or other securities entitled to vote
            for the election of directors or other managing authority.
            <br />
            -
            "Account" means a unique account created for you to access our
            service or parts of our service.
            <br />
            - "Website" refers to TradeMaster,
            accessible from https://TradeMaster.live/.
            <br />
            - "Service" refers to the
            website.
            <br />
            - "Country" refers to India.
            <br />
            - "Service Provider" means any
            natural or legal person who processes data on behalf of the company.
            It refers to third-party companies or individuals employed directly
            or indirectly by the
          </p>
          
        </div>
        <div className="privacyPolicy__description">
          <p>
            company to facilitate the service, provide the service on behalf of the company, perform services related to the service, or assist the company in analyzing how the service is used.
            <br />
            - "Third-party Social Media Service" refers to any website or social network website through which a user can log in or create an account to use the service.
            <br />
            - "Personal Data" refers to any information that relates to an identified or identifiable individual.
            <br />
            - "Cookies" are small files that are placed on your computer, mobile device, or any other device by a website, containing details of your browsing history on that website among its many uses.
            <br />
            - "Device" means any device that can access the service, such as a computer, mobile phone, smartphone, or digital tablet.
            <br />
            - "Usage Data" refers to data collected automatically, either generated by the use of the service or from the service infrastructure itself (e.g., the duration of a page visit).
          </p>
          <h2>Collecting and Using Your Personal Data</h2>
          <h3>Types of Data Collected</h3>
          <h4>Personal Data</h4>
          <p>
            While using our service, we may ask you to provide us with certain  
            personally identifiable information that can be used to contact or
            identify you ("Personal Data"). Personally identifiable information
            may include, but is not limited to: 
            - Email address 
            - First name and Last name
            - Phone number
          </p>
          <h4>Usage Data</h4>
          <p>Usage Data is collected automatically when using the service.</p>
          <p>
            Usage Data may include information such as your Device's Internet
            Protocol address (e.g., IP address), browser type, browser version,
            the pages of our service that you visit, the time and date of your
            visit, the time spent on those pages, unique device identifiers and
            other diagnostic data.
          </p>
          <p>
            When you access the service by or through a mobile device, we may
            collect certain information automatically, including, but not
            limited to, the type of mobile device you use, your mobile device
            unique ID, the IP address of your mobile device, your mobile
            operating system, the type of mobile Internet browser you use,
            unique device identifiers and other diagnostic data.
          </p>
          <p>
            We may also collect information that your browser sends whenever you
            visit our service or when you access the service by or through a
            mobile device.
          </p>
          <h4>Tracking Technologies and Cookies</h4>
          <p>
            We use cookies and similar tracking technologies to track the
            activity on our service and we hold certain information.
          </p>
          <p>
            Cookies are files with a small amount of data which may include an
            anonymous unique identifier. Cookies are sent to your browser from a
            website and stored on your device. Other tracking technologies are
            also used such as beacons, tags, and scripts to collect and track
            information and to improve and analyze our service.
          </p>
          <p>
            You can instruct your browser to refuse all cookies or to indicate
            when a cookie is being sent. However, if you do not accept cookies,
            you may not be able to use some portions of our service.
          </p>
          <p>Examples of Cookies we use:</p>
          <p>
            - Session Cookies. We use Session Cookies to operate our service.
            <br />
            - Preference Cookies. We use Preference Cookies to remember your
            preferences and various settings.
            <br />
            - Security Cookies. We use Security Cookies for security purposes.
          </p>
          <h2>Use of Your Personal Data</h2>
          <p>The company may use Personal Data for the following purposes:</p>
          <p>
            - To provide and maintain our service, including to monitor the 
            usage of our service.
            <br />
            - To manage your account: to manage your registration as a user of the service. The Personal Data you provide can give you access to different functionalities of the service that are available to you as a registered user. 
            <br />
            - For the performance of a contract: the development, compliance, and undertaking of the purchase contract for the products, items, or services you have purchased or of any other contract with us through the service.
            <br />
            - To contact you: To contact you by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products, or contracted services, including the security updates, when necessary or reasonable for their implementation.
            <br />
            - To provide you with news, special offers, and general information about other goods, services, and events which we offer that are similar to those that you have already purchased or enquired about unless you have opted not to receive such information.
            <br />
            - To manage your requests: To attend and manage your requests to us.
          </p>
          <p>
            - For business transfers: We may use your information to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by us about our service users is among the assets transferred.
            <br />
            - For other purposes: We may use your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns, and to evaluate and improve our service, products, services, marketing, and your experience.
          </p>
          <p>
            We may share your personal information in the following situations:
          </p>
          <p>
            - With service providers: We may share your personal information with service providers to monitor and analyze the use of our service, to contact you.
            <br />
            - For business transfers: We may share or transfer your personal information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.
            <br />
            - With business partners: We may share your information with our business partners to offer you certain products, services, or promotions.
            <br />
            - With other users: when you share personal information or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly distributed outside.
            <br />
            - With your consent: We may disclose your personal information for any other purpose with your consent.
          </p>
          <h2>Retention of Your Personal Data</h2>
          <p>
            The company will retain your Personal Data only for as long as is
            necessary for the purposes set out in this Privacy Policy. We will
            retain and use your Personal Data to the extent necessary to comply
            with our legal obligations (for example, if we are required to
            retain your data to comply with applicable laws), resolve disputes,
            and enforce our legal agreements and policies.
          </p>
          <p>
            The company will also retain Usage Data for internal analysis
            purposes. Usage Data is generally retained for a shorter period,
            except when this data is used to strengthen the security or to
            improve the functionality of our service, or we are legally
            obligated to retain this data for longer time periods.
          </p>
          <h2>Transfer of Your Personal Data</h2>
          <p>
            Your information, including Personal Data, is processed at the
            company's operating offices and in any other places where the 
            parties involved in the processing are located. It means that this
            information may be transferred to — and maintained on — computers
            located outside of your state, province, country, or other
            governmental jurisdiction where the data protection laws may differ
            from those of your jurisdiction.
          </p>
          <p>
            Your consent to this Privacy Policy followed by your submission of
            such information represents your agreement to that transfer.
          </p>
          <p>
            The company will take all steps reasonably necessary to ensure that
            your data is treated securely and in accordance with this Privacy
            Policy and no transfer of your Personal Data will take place to an
            organization or a country unless there are adequate controls in
            place including the security of your data and other personal
            information.
          </p>
          <h2>Disclosure of Your Personal Data</h2>
          <h3>Business Transactions</h3>
          <p>
            If the company is involved in a merger, acquisition, or asset sale,
            your Personal Data may be transferred. We will provide notice before
            your Personal Data is transferred and becomes subject to a
            different Privacy Policy.
          </p>
          <h3>Law Enforcement</h3>
          <p>
            Under certain circumstances, the company may be required to disclose
            your Personal Data if required to do so by law or in response to
            valid requests by public authorities (e.g. a court or a government
            agency).
          </p>
          <h3>Other legal requirements</h3>
          <p>
            The company may disclose your Personal Data in the good faith belief
            that such action is necessary to:
          </p>
          <p>
            - Comply with a legal obligation
            <br />
            - Protect and defend the rights or property of the company
            <br />
            - Prevent or investigate possible wrongdoing in connection with the
            service
            <br />
            - Protect the personal safety of users of the service or the public
            <br />
            - Protect against legal liability
          </p>
          <h3>Security of Your Personal Data</h3>
          <p>
            The security of your Personal Data is important to us, but remember
            that no method of transmission over the Internet, or method of
            electronic storage is 100% secure. While we strive to use
            commercially acceptable means to protect your Personal Data, we
            cannot guarantee its absolute security.
          </p>
          <h2>Children's Privacy</h2>
          <p>
            Our service does not address anyone under the age of 18. We do not
            knowingly collect personally identifiable information from anyone
            under the age of 18. If you are a parent or guardian and you are
            aware that your child has provided us with Personal Data, please
            contact us. If we become aware that we have collected Personal Data
            from anyone under the age of 18 without verification of parental
            consent, we take steps to remove that information from our servers.
          </p>
          <h2>Changes to this Privacy Policy</h2>
          <p>
            We may update our Privacy Policy from time to time. We will notify
            you of any changes by posting the new Privacy Policy on this page.
          </p>
          <p>
            We will let you know via email and/or a prominent notice on our
            service, prior to the change becoming effective and update the
            "effective date" at the top of this Privacy Policy.
          </p>
          <p>
            You are advised to review this Privacy Policy periodically for any
            changes. Changes to this Privacy Policy are effective when they are
            posted on this page.
          </p>
          <h1>Contact Us</h1>
          <p>
            If you have any questions about this Privacy Policy, please contact
            us:
          </p>
          <p>
            - By email: customerservice@trademaster.live
            <br />
            - By visiting this page on our website: : https://trademaster.live
          </p>
        </div>
      </div>
    </div>
  );
};
