import { AvailableBrokers } from "../constants/AvailableBrokers";
import { useState } from "react";
import { DropDown } from "./DropDown";
import "../styles/SetupDemat.css";
import {
  AddDematAccount,
  AddAPI,
  DeleteAPI,
  DeleteDematAccount,
} from "../apis/TradeMasterApi";
import { Notification, notify } from "./Notification";

import { AiOutlineCloseSquare } from "react-icons/ai";

export const SetupDemat = ({
  setIsSetupBroker,
  addedClientID,
  addedBroker,
  addedApiKey,
  addedApiSecret,
}) => {
  const [selectedBroker, setSelectedBroker] = useState(addedBroker);
  const [clientID, setClientID] = useState(addedClientID);
  const [clientIDSubmitLoading, setClientIDSubmitLoading] = useState(false);
  const [isClientIDAdded, setIsClientIDAdded] = useState(addedClientID);
  const [apiKey, setAPIKey] = useState(addedApiKey);
  const [apiSecret, setAPISecret] = useState(addedApiSecret);
  const [apiKeySubmitLoading, setAPIKeySubmitLoading] = useState(false);
  const [apiDeleteSubmitLoading, setDeleteSubmitLoading] = useState(false);

  const handleChange = (e) => {
    setClientID(e.target.value);
  };

  const handleClientIDSubmit = async () => {
    setClientIDSubmitLoading(true);
    try {
      const response = await AddDematAccount(selectedBroker.name, clientID);
      notify("success", response.message);
      setIsClientIDAdded(clientID);
      setClientIDSubmitLoading(false);
    } catch (error) {
      notify("error", error.response.data.message);
      setClientIDSubmitLoading(false);
    }
  };

  const handleDeleteSubmit = async (e) => {
    e.preventDefault();
    setDeleteSubmitLoading(true);
    try {
      await DeleteAPI(clientID, selectedBroker.name);
      setAPIKey("");
      setAPISecret("");
      setDeleteSubmitLoading(false);
    } catch (error) {
      notify("error", error.response.data.message);
      setDeleteSubmitLoading(false);
    }
    try {
      const response = await DeleteDematAccount();
      setClientID("");
      notify("success", response.message);
      apiDeleteSubmitLoading(false);
      setClientID("");
      setIsClientIDAdded("");
      selectedBroker("");
      setAPIKey("");
      setAPISecret("");
    } catch (error) {
      notify("error", error.response.data.message);
      apiDeleteSubmitLoading(false);
    }
  };

  const handleAPISubmit = async (e) => {
    e.preventDefault();
    setAPIKeySubmitLoading(true);
    try {
      const response = await AddAPI(
        clientID,
        apiKey,
        apiSecret,
        selectedBroker.name
        );
        notify("success", response.message);
        setAPIKeySubmitLoading(false);
    } catch (error) {
      notify("error", error.response.data.message);
      setAPIKeySubmitLoading(false);
    }
  };

  return (
    <div className="setupdemat">
      <Notification />
      <div
        className="setupdemat__close"
        onClick={() => setIsSetupBroker(false)}
      >
        {" "}
        <AiOutlineCloseSquare />{" "}
      </div>
      <div className="setupDemat__content">
        <h1 className="setupdemat__heading">Setup Demat</h1>
        <div className="setupdemat__dropdown">
          <DropDown
            optionsFor="Brokers"
            options={AvailableBrokers}
            selectedOption={selectedBroker}
            setSelectedOption={setSelectedBroker}
            disabled={isClientIDAdded === "" ? false : true}
          />
        </div>
        <form>
          <div className="setupdemat__form">
            <div className="setupdemat__form-group">
              <label htmlFor="clientID" className="setupdemat__form-lable">
                Client ID
              </label>
              <input
                type="text"
                id="clientID"
                className="setupdemat__form-input"
                name="clientID"
                value={clientID}
                onChange={handleChange}
                disabled={isClientIDAdded === "" ? false : true}
              />
            </div>
          </div>
          {isClientIDAdded ? null : (
            <button
              className="setupdemat__form-button"
              onClick={handleClientIDSubmit}
              disabled={clientIDSubmitLoading}
            >
              {clientIDSubmitLoading ? "Submitting..." : "Submit"}
            </button>
          )}
          {isClientIDAdded && (
            <div className="setupdemat__form-success">
              <form onSubmit={handleAPISubmit}>
                <div className="setupdemat__form-group">
                  <label htmlFor="apiKey" className="setupdemat__form-lable">
                    API Key
                  </label>
                  <input
                    type="text"
                    id="apiKey"
                    className="setupdemat__form-input"
                    name="apiKey"
                    value={apiKey}
                    onChange={(e) => setAPIKey(e.target.value)}
                  />
                </div>
                {selectedBroker.name !== "Motilal" && (
                  <div className="setupdemat__form-group">
                    <label
                      htmlFor="apiSecret"
                      className="setupdemat__form-lable"
                    >
                      API Secret
                    </label>
                    <input
                      type="password"
                      id="apiSecret"
                      className="setupdemat__form-input"
                      name="apiSecret"
                      value={apiSecret}
                      onChange={(e) => setAPISecret(e.target.value)}
                    />
                  </div>
                )}
                <button
                  className="setupdemat__form-button"
                  onClick={handleAPISubmit}
                >
                  {apiKeySubmitLoading ? "Submitting..." : "Submit"}
                </button>
                <button
                  className="deletedemat__form-button"
                  onClick={handleDeleteSubmit}
                >
                  {apiDeleteSubmitLoading ? "Deleting..." : "Delete"}
                </button>
              </form>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};
