import { StrategyCard } from "./StrategyCard";
import "../styles/Strategies.css";

export const Strategies = ({client_id, broker_name}) => {

  return (
    <div className="strategies">
      <div className="strategies__body">
        <div className="strategies__body__card">
          <StrategyCard name="Forever-Young" status="inactive" multiplier="1" client_id={client_id} broker_name={broker_name} algo_name="forever_young"/>
          <StrategyCard name="Risk-Takers" status="inactive" multiplier="4" client_id={client_id} broker_name={broker_name} algo_name="risk_takers"/>
        </div>
      </div>
    </div>
  );
};
