import "../styles/Loader.css";

export const Loader = () => {
    return (
        <div className="loader">
            <div className="loader__body">
                <div className="loader__body__spinner">
                </div>
            </div>
        </div>
    );
}