import { useEffect, useState } from "react";
import { RunAlgo, GetStatus } from "../apis/TradeMasterApi";
import { Notification, notify } from "./Notification";

import "../styles/StrategyCard.css";

export const StrategyCard = ({
  name,
  description,
  client_id,
  broker_name,
  algo_name,
}) => {
  const [status, setStatus] = useState(
    localStorage.getItem(algo_name + "-status")
  ); // ["active", "inactive", "running", "pending", "error"] => ["green", "red", "yellow", "blue", "red"] => "green red yellow blue red"]
  const [runAlgoLoading, setRunAlgoLoading] = useState(false);
  const [multiplier, setMultiplier] = useState(0);
  useEffect(() => {
    async function fetchData() {
      try {
        console.log("client_id", client_id);
        await GetStatus(client_id, algo_name).then((response) => {
          setStatus(response.status);
          setMultiplier(response.multiplier*response.lots);
          console.log(response.status);
          localStorage.setItem(algo_name + "-status", response.status);
        });
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, [client_id, broker_name, algo_name]);

  const handleRunAlgo = async () => {
    setRunAlgoLoading(true);
    localStorage.setItem(algo_name + "-status", "pending");
    setStatus("pending");
    try {
      const response = await RunAlgo(client_id, broker_name, algo_name);
      if(response.message === "Algo already running" || response.message === "running")
      {
      notify("success", response.message);
      localStorage.setItem(algo_name + "-status", "running");
      setStatus("running");
      }
      else
      {
        notify("error", response.message);
        localStorage.setItem(algo_name + "-status", "error");
        setStatus("error");
      }
    } catch (error) {
      console.log(error);
      notify("error", error.response.data.message);
      if(error.response.data.message === "Algo is already running")
      {
        localStorage.setItem(algo_name + "-status", "running");
        setStatus("running");
      }
      else
      {
        console.log(error);
        localStorage.setItem(algo_name + "-status", "error");
        setStatus("none");
      }
      setRunAlgoLoading(false);
    }
  };
  return (
    <div className="strategy__card">
      <Notification />
      <div className="strategy__card__header">
        <div
          className={`strategy__card__header__strip--${algo_name + "-status"}`}
        />
        <div className="strategy__card__header__left__title">
          <p>{name}</p>
        </div>
      </div>
      <div className="strategy__card__body">
        <div className="strategy__card__body__description">
          <p>{description}</p>
        </div>
        <div className="strategy__card__body__multiplier">
          <p>
            Multiplier
            <span className="strategy__card__body__multiplier-value">
              {multiplier}
            </span>
          </p>
        </div>
      </div>
      <div className="strategy__card__interactive">
        <button
          className={`strategy__card__interactive__run--${status}`}
          onClick={handleRunAlgo}
          disabled={runAlgoLoading}
        >
          {`${status}` !== "live"
            ? status === "pending_live"
              ? status === "error"? "Error"
                              :"Pending"
                        : "Run"
                  : "Running"}
        </button>
      </div>
    </div>
  );
};
