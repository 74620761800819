import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";

import { UserRegisterConfirm } from "../apis/TradeMasterApi";
import Logo from "../assets/logos/Logo.png";
import { Loader } from "../components/Loader";
import { Notification } from "../components/Notification";

import "../styles/Register.css";

export const RegisterConfirm = () => {
    const [searchParams] = useSearchParams();
    const token = searchParams.get("token");
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        const handleRegisterConfirm = async () => {
            setLoading(true);
            try {
                const response = await UserRegisterConfirm(token);
                setMessage(response.message);
                setSuccess(true);
                setLoading(false);
            } catch (error) {
                setMessage(error.response.data.message);
                setSuccess(false);
                setLoading(false);
            }
        };
        handleRegisterConfirm();
    }, [token]);

    return (
        <div className="register">
            <Notification />
            <div className="register__content">
                <div className="register__logo">
                    <img src={Logo} alt="logo" />
                </div>
                <div className="register__form">
                    {loading ? (
                        <div className="register__form-loading">
                            <Loader />
                        </div>
                    ) : (
                        <div className="register__form-message">
                            {success ? (
                                <div className="register__form-message-success">
                                    <h1>{message}</h1>
                                    <div className="register__form-message-success__link">
                                        <a href="/login">Login</a>
                                    </div>
                                </div>
                            ) : (
                                <div className="register__form-message-error">
                                    <h1>{message}</h1>
                                    <div className="register__form-message-error__link">
                                        <a href="/register">Register</a>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};