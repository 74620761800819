import { useState } from "react";
import { LoginUser } from "../apis/TradeMasterApi";

import "../styles/Login.css";
import Logo from "../assets/logos/Logo.png";
import { Notification, notify } from "../components/Notification";

export const Login = () => {
  const isAuthenticated = localStorage.getItem("isAuthenticated");
  if (isAuthenticated) {
    window.location.href = "/dashboard";
  }

  const [ username, setUsername ] = useState("");
  const [ password, setPassword ] = useState("");

  const [loading, setLoading] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await LoginUser(username, password);
      notify("success", "Logged in successfully");
      localStorage.setItem("isAuthenticated", response.access_token);
      window.location.href = "/dashboard";
      setLoading(false);
    } catch (error) {
      notify("error", error.response.data.message);
      setLoading(false);
    }
  };

  return (
    <div className="login">
      <Notification />
      <div className="login__content">
        <div className="login__logo">
          <img src={Logo} alt="logo" />
        </div>
        <form className="login__form" onSubmit={handleLogin}>
          <div className="login__inputfield">
            <div className="login__inputfield_label">
              <label>Username</label>
            </div>
            |
            <div className="login__inputfield_input">
              <input
                type="text"
                name="username"
                id="username"
                placeholder="Enter your username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </div>
          </div>
          <div className="login__inputfield">
            <div className="login__inputfield_label">
              <label>Password</label>
            </div>
            |
            <div className="login__inputfield__input">
              <input
                type="password"
                name="password"
                id="password"
                placeholder="Enter your password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
          </div>
          <div className="login__interactive">
            <button
              type="submit"
              className="login__button-login"
              disabled={loading}
            >
              {loading ? "Logging in..." : "Login"}
            </button>
          </div>
        </form>
        <div className="login__footer">
          <div className="login__footer__left">
            <div className="login__footer__left__link">
              <a href="/register">Sign Up</a>
            </div>
          </div>
          <div className="login__footer__right">
            <div className="login__footer__right__link">
              <a href="/forgot/password">Forgot Password?</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
