import axios from "axios";

const baseUrl = "https://dev-tm-api.onrender.com";
// const baseUrl = "http://localhost:5000";

export const CheckLogin = async () => {
  try {
    const jwt_token = localStorage.getItem("isAuthenticated");
    const response = await axios.get(`${baseUrl}/api/check/login`, {
      headers: {
        Authorization: `Bearer ${jwt_token}`,
      },
    });
    console.log(response);
    return response.data;
  } catch {
    localStorage.clear();
    window.location.href = "/login";
  }
};

export const UserRegister = async (
  firstname,
  lastname,
  username,
  password,
  email,
  phone
) => {
  const response = await axios.post(`${baseUrl}/api/register`, {
    firstname,
    lastname,
    username,
    password,
    email,
    phone,
  });
  return response.data;
};

export const UserRegisterConfirm = async (token) => {
  const response = await axios.get(
    `${baseUrl}/api/register/confirm?token=${token}`
  );
  return response.data;
};

export const LoginUser = async (username, password) => {
  const response = await axios.post(`${baseUrl}/api/login`, {
    username,
    password,
  });
  return response.data;
};

export const UserForgotPassword = async (email) => {
  const response = await axios.post(`${baseUrl}/api/forgot/password`, {
    email,
  });
  return response.data;
};

export const UserResetPassword = async (token, password) => {
  const response = await axios.post(
    `${baseUrl}/api/reset/password?token=${token}`,
    {
      password,
    }
  );
  return response.data;
};

export const AddDematAccount = async (brokerName, clientID) => {
  const jwt_token = localStorage.getItem("isAuthenticated");
  const response = await axios.post(
    `${baseUrl}/api/add/demat`,
    {
      broker_name: brokerName,
      client_id: clientID,
    },
    {
      headers: {
        Authorization: `Bearer ${jwt_token}`,
      },
    }
  );
  return response.data;
};

export const GetUser = async () => {
  const jwt_token = localStorage.getItem("isAuthenticated");
  const response = await axios.get(`${baseUrl}/api/get/user`, {
    headers: {
      Authorization: `Bearer ${jwt_token}`,
    },
  });
  return response.data;
};

export const GetDematAccount = async () => {
  const jwt_token = localStorage.getItem("isAuthenticated");
  const response = await axios.get(`${baseUrl}/api/get/demat`, {
    headers: {
      Authorization: `Bearer ${jwt_token}`,
    },
  });
  return response.data;
};

export const UpdateDematAccount = async (brokerName, clientID) => {
  const jwt_token = localStorage.getItem("isAuthenticated");
  const response = await axios.put(
    `${baseUrl}/api/update/demat`,
    {
      broker_name: brokerName,
      client_id: clientID,
    },
    {
      headers: {
        Authorization: `Bearer ${jwt_token}`,
      },
    }
  );
  return response.data;
};

export const DeleteDematAccount = async () => {
  const jwt_token = localStorage.getItem("isAuthenticated");
  const response = await axios.delete(`${baseUrl}/api/delete/demat`, {
    headers: {
      Authorization: `Bearer ${jwt_token}`,
    },
  });
  return response.data;
};

export const AddAPI = async (cleintID, apiKey, apiSecret, brokerName) => {
  const jwt_token = localStorage.getItem("isAuthenticated");
  const response = await axios.post(
    `${baseUrl}/api/add/api`,
    {
      client_id: cleintID,
      api_key: apiKey,
      api_secret: apiSecret,
      broker_name: brokerName,
    },
    {
      headers: {
        Authorization: `Bearer ${jwt_token}`,
      },
    }
  );
  return response.data;
};

export const GetAPI = async (client_id, broker_name) => {
  const jwt_token = localStorage.getItem("isAuthenticated");
  const response = await axios.get(`${baseUrl}/api/get/api`, {
    headers: {
      Authorization: `Bearer ${jwt_token}`,
    },
    params: {
      broker_name: broker_name,
      client_id: client_id,
    },
  });
  return response.data;
};

export const UpdateAPI = async (
  cleintID,
  apiKey,
  apiSecret,
  brokerName,
  accessToken = "",
  vendorTOken = ""
) => {
  const jwt_token = localStorage.getItem("isAuthenticated");
  const response = await axios.put(
    `${baseUrl}/api/update/api`,
    {
      client_id: cleintID,
      api_key: apiKey,
      api_secret: apiSecret,
      broker_name: brokerName,
      access_token: accessToken,
      vendor_token: vendorTOken,
    },
    {
      headers: {
        Authorization: `Bearer ${jwt_token}`,
      },
    }
  );
  return response.data;
};

export const DeleteAPI = async (clientID, brokerName) => {
  const jwt_token = localStorage.getItem("isAuthenticated");
  const response = await axios.delete(
    `${baseUrl}/api/delete/api?client_id=${clientID}&broker_name=${brokerName}`,
    {
      headers: {
        Authorization: `Bearer ${jwt_token}`,
        "Content-Type": "application/json",
      },
    }
  );
  return response.data;
};

export const NuvamaLogin = async (apiKey, apiSecret, requestID) => {
  const jwt_token = localStorage.getItem("isAuthenticated");
  const response = await axios.post(
    `${baseUrl}/api/broker/nuvama/login`,
    {
      api_key: apiKey,
      api_secret: apiSecret,
      request_id: requestID,
    },
    {
      headers: {
        Authorization: `Bearer ${jwt_token}`,
      },
    }
  );
  return response.data;
};

export const NuvamaMargin = async (
  clientID,
  apiKey,
  vendorToken,
  accessToken
) => {
  const jwt_token = localStorage.getItem("isAuthenticated");
  const response = await axios.get(`${baseUrl}/api/broker/nuvama/limits`, {
    headers: {
      Authorization: `Bearer ${jwt_token}`,
    },
    params: {
      client_id: clientID,
    },
  });
  return response.data;
};

export const WisdomCapitalLogin = async (apiKey, apiSecret) => {
  const jwt_token = localStorage.getItem("isAuthenticated");
  const response = await axios.post(
    `${baseUrl}/api/broker/wisdom_capital/login`,
    {
      api_key: apiKey,
      api_secret: apiSecret
    },
    {
      headers: {
        Authorization: `Bearer ${jwt_token}`,
      },
    }
  );
  return response.data;
};

export const WisdomCapitalMargin = async (clientID) => {
  console.log(clientID);
  const jwt_token = localStorage.getItem("isAuthenticated");
  const response = await axios.get(`${baseUrl}/api/broker/wisdom_capital/limits`, {
    headers: {
      Authorization: `Bearer ${jwt_token}`,
    },
    params: {
      client_id: clientID,
    },
  });
  return response.data;
};

export const RunAlgo = async (client_id, broker_name, algo_name) => {
  const jwt_token = localStorage.getItem("isAuthenticated");
  try{
  const response = await axios.post(
    `${baseUrl}/api/run/algo`,
    {
      client_id: client_id,
      broker_name: broker_name,
      algo_name: algo_name,
    },
    {
      headers: {
        Authorization: `Bearer ${jwt_token}`,
      },
    }
  );
  return response.data;
  }
  catch(err){
    // console.log(err.response.data);
    return err.response.data;
  }
};


export const GetStatus = async (client_id, algo_name) => {
  const jwt_token = localStorage.getItem("isAuthenticated");
  console.log(client_id, algo_name);
  const response = await axios.get(`${baseUrl}/api/get/status`, {
    headers: {
      Authorization: `Bearer ${jwt_token}`,
    },
    params: {
      client_id: client_id,
      algo_name: algo_name,
    },
  });
  return response.data;
};
