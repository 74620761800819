import "../styles/PrivacyPolicy.css";
import Logo from "../assets/logos/Logo.png";

export const TAC = () => {
  return (
    <div className="privacypolicy">
      <div className="privacypolicy__content">
        <div className="privacypolicy__title">
          <h2>User Agreement</h2>
          <div className="privacypolicy__title_logo">
            <img src={Logo} alt="logo" />
          </div>
        </div>
        <div className="privacypolicy__description">
          <p>
            This User Agreement ("Agreement") is entered into between you
            ("User" or "you") and TradeMaster.live ("Company" or "we") and
            governs your use of the TradeMaster.live website ("Site") and any
            services provided through the Site ("Services"). By accessing the
            Site and using the Services, you agree to be bound by this
            Agreement.
          </p>
          <ul>
            <li>
              <h3>1. Acceptance of Terms</h3>
              <p>
                By accessing the Site and using the Services, you agree to be
                bound by this Agreement. If you do not agree to the terms and
                conditions contained in this Agreement, you may not access or
                otherwise use the Site or the Services.
              </p>
            </li>
            <li>
              <h3>2. Eligibility</h3>
              <p>
                You must be at least 18 years of age to use the Site or the
                Services. By accessing the Site or using the Services, you
                represent and warrant that you are at least 18 years old.
              </p>
            </li>
            <li>
              <h3>3. User Obligations</h3>
              <ul>
                <li>
                  <h4>3.1. Compliance with Laws</h4>
                  <p>
                    You agree to comply with all applicable laws, statutes,
                    ordinances, and regulations regarding your use of the Site
                    and the Services.
                  </p>
                </li>
                <li>
                  <h4>3.2. Accuracy of Information</h4>
                  <p>
                    You represent and warrant that all information provided by
                    you on the Site is accurate, complete, and up-to-date.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <h3>4. Intellectual Property Rights</h3>
              <ul>
                <li>
                  <h4>4.1. Ownership</h4>
                  <p>
                    The Site and the Services are owned and operated by the
                    Company. The visual interfaces, graphics, design,
                    compilation, information, data, computer code (including
                    source code or object code), products, software, services,
                    and all other elements of the Site and the Services
                    ("Materials") provided by the Company are protected by all
                    relevant intellectual property and proprietary rights and
                    applicable laws. All Materials contained in the Site or the
                    Services are the property of the Company or our third-party
                    licensors. Except as expressly authorized by the Company,
                    you may not make use of the Materials. The Company reserves
                    all rights to the Materials not granted expressly in this
                    Agreement.
                  </p>
                </li>
                <li>
                  <h4>4.2. Limited License</h4>
                  <p>
                    Subject to the terms and conditions of this Agreement, the
                    Company grants you a limited, non-exclusive, and
                    non-transferable license to access and use the Site and the
                    Services for your personal, non-commercial purposes. You may
                    not use the Site or the Services for any other purpose, or
                    modify, copy, distribute, transmit, display, perform,
                    reproduce, publish, license, create derivative works from,
                    transfer, or sell any information, software, products, or
                    services obtained from the Site or the Services. You may not
                    reverse engineer, decompile, disassemble, or otherwise
                    attempt to derive the source code of the Site or the
                    Services, or permit or authorize a third party to do so,
                    except to the extent such activities are expressly permitted
                    by law. You may not remove, alter, or obscure any copyright,
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <h3>5. Prohibited Conduct</h3>
              <p>When using the Site and Services, you agree not to:</p>
              <ul>
                <li>
                  <p>Violate any applicable laws or regulations.</p>
                </li>
                <li>
                  <p>
                    Infringe upon the intellectual property or other rights of
                    the Company or any third party.
                  </p>
                </li>
                <li>
                  <p>
                    Engage in any fraudulent, misleading, or deceptive
                    activities.
                  </p>
                </li>
                <li>
                  <p>
                    Interfere with the proper functioning of the Site and
                    Services.
                  </p>
                </li>
                <li>
                  <p>Transmit any viruses, malware, or other harmful code.</p>
                </li>
                <li>
                  <p>
                    Use the Site or Services for any illegal or unauthorized
                    purpose.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <h3>6. Electronic Communications</h3>
              <ul>
                <li>
                  <h4>6.1. Consent to Electronic Communications</h4>
                  <p>
                    By using the Site or the Services, you consent to receiving
                    certain electronic communications from us as further
                    described in our Privacy Policy. Please read our Privacy
                    Policy to learn more about our electronic communications
                    practices. You agree that any notices, agreements,
                    disclosures, or other communications that we send to you
                    electronically will satisfy any legal communication
                    requirements, including that such communications be in
                    writing.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <h3>7. Limitation of Liability</h3>
              <ul>
                <li>
                  <h4>7.1. No Warranty</h4>
                  <p>
                    The Site and Services are provided on an "as is" and "as
                    available" basis without any warranties or representations,
                    either expressed or implied. We do not warrant that the Site
                    and Services will be uninterrupted, error-free, or free from
                    viruses or other harmful components.
                  </p>
                </li>
                <li>
                  <h4>7.2. Limitation of Liability</h4>
                  <p>
                    To the fullest extent permitted by law, we shall not be
                    liable for any indirect, incidental, special, consequential,
                    or punitive damages, or any loss of profits or revenue,
                    arising out of or in connection with your use of the Site
                    and Services.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <h3>8. Indemnification</h3>
              <p>
                You agree to indemnify and hold harmless the Company and its
                officers, directors, employees, and agents from and against any
                and all claims, liabilities, damages, losses, or expenses,
                including reasonable attorneys' fees and costs, due to or
                arising out of your use of the Site or the Services, your
                violation of this Agreement, or your violation, infringement, or
                misappropriation of any third-party rights.
              </p>
            </li>
            <li>
              <h3>9. Termination</h3>
              <p>
                We may terminate this Agreement at any time for any reason
                without notice to you. You may terminate this Agreement by
                ceasing to use the Site and Services. Upon termination, you must
                destroy all copies of any aspect of the Site or Services in your
                possession. The provisions of this Agreement that are intended
                to survive termination shall survive.
              </p>
            </li>
            <li>
              <h3>10. Governing Law</h3>
              <p>
                This Agreement shall be governed by the laws of the State of
                California without regard to conflict of law principles. To the
                extent that any lawsuit or court proceeding is permitted
                hereunder, you and the Company agree to submit to the personal
                and exclusive jurisdiction of the state courts and federal
                courts located within Santa Clara County, California for the
                purpose of litigating all such disputes.
              </p>
            </li>
            <li>
              <h3>11. Entire Agreement</h3>
              <p>
                This Agreement constitutes the entire agreement between you and
                the Company regarding the use of the Site and Services,
                superseding any prior agreements or understandings, whether oral
                or written.
              </p>
            </li>
            <li>
              <h3>12. Severability</h3>
              <p>
                If any provision of this Agreement is determined to be invalid,
                illegal, or unenforceable, the remaining provisions shall
                continue in full force and effect.
              </p>
            </li>
            <li>
              <h3>13. Modifications</h3>
              <p>
                We reserve the right to modify this Agreement at any time. The
                most current version of the Agreement will be posted on the
                Site. Your continued use of the Site and Services after any
                modifications to the Agreement indicates your acceptance of the
                modified terms.
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
