import React, { useState } from "react";
import "../styles/DropDown.css";
import { AiFillCaretDown, AiFillCaretRight } from "react-icons/ai";

export const DropDown = ({ optionsFor, options, selectedOption, setSelectedOption }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleMenu = () => setIsOpen(!isOpen);

  const handleChange = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  return (
    <div className="dropdown">
      <button className="dropdown-toggle" onClick={toggleMenu}>
        {selectedOption ? (
          <div className="selected__item">
            <div className="selected__item__icon">
              <img src={selectedOption.icon} alt={selectedOption.name}></img>
            </div>
            {selectedOption.name}
          </div>
        ) : (
          <div className="selected__item">Select {optionsFor}</div>
        )}
        <span className="dropdown-toggle__icon">
          {isOpen ? <AiFillCaretDown /> : <AiFillCaretRight />}
        </span>
      </button>
      {isOpen && (
        <ul className="dropdown-menu">
          {options.map((option) => (
            <li key={option} onClick={() => handleChange(option)}>
              <img
                className="menu__item-icon"
                src={option.icon}
                alt={option.name}
              ></img>
              {option.name}
            </li>
          ))}
        </ul>

        // <ul className="dropdown-menu">
        //   <li onSelect={handleChange("Motilal")}>
        //     <img
        //       className="menu__item-icon"
        //       src={MotilalIcon}
        //       alt="motilal"
        //     ></img>
        //     Motilal
        //   </li>
        //   <li onSelect={handleChange("Nuvama")}>
        //     <img
        //       className="menu__item-icon"
        //       src={NuvamaIcon}
        //       alt="nuvama"
        //     ></img>
        //     Nuvama
        //   </li>
        // </ul>
      )}
    </div>
  );
};
