import React, { useState } from "react";
import { notify, Notification } from "../components/Notification";
import { UserForgotPassword } from "../apis/TradeMasterApi";

import "../styles/ForgotPassword.css"

export const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await UserForgotPassword(email);
      setLoading(false);
      notify("success", response.message);
    } catch (error) {
      setLoading(false);
      notify("error", error.response.data.message);
    }
  };

  return (
    <div className="forgotpassword">
      <div className="forgotpassword__container">
        <Notification />
        <div className="forgotpassword__container__body">
          <div className="forgotpassword__container__body__header">
            <h1>Forgot Password</h1>
          </div>
          <div className="forgotpassword__container__body__form">
            <form onSubmit={handleSubmit}>
              <div className="forgotpassword__container__body__form__input">
                <input
                  type="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="forgotpassword__container__body__form__submit">
                <button
                  type="submit"
                  className="register__form-button"
                  disabled={loading}
                >
                  {loading ? "Loading..." : "Send Reset Link"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
