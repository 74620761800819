import { useState } from "react";

import { UserRegister } from "../apis/TradeMasterApi";
import Logo from "../assets/logos/Logo.png";

import "../styles/Register.css";
import { Notification, notify } from "../components/Notification";

export const Register = () => {
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const [loading, setLoading] = useState(false);

  const handleRegister = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await UserRegister(
        firstname,
        lastname,
        username,
        password,
        email,
        phone
      );
      notify("success", response.message);
      setLoading(false);
    } catch (error) {
      notify("error", error.response.data.message);
      setLoading(false);
    }
  };

  return (
    <div className="register">
      <Notification />
      <div className="register__content">
        <div className="register__logo">
          <img src={Logo} alt="logo" />
        </div>
        <form className="register__form" onSubmit={handleRegister}>
          <div className="register__form-group">
            <label className="register__form-label" htmlFor="firstname">
              First Name
            </label>
            |
            <input
              type="text"
              name="firstname"
              id="firstname"
              placeholder="Enter your first name"
              value={firstname}
              onChange={(e) => setFirstname(e.target.value)}
              required
            />
          </div>
          <div className="register__form-group">
            <label className="register__form-label" htmlFor="lastname">
              Last Name
            </label>
            |
            <input
              type="text"
              name="lastname"
              id="lastname"
              placeholder="Enter your last name"
              value={lastname}
              onChange={(e) => setLastname(e.target.value)}
              required
            />
          </div>
          <div className="register__form-group">
            <label className="register__form-label" htmlFor="username">
              Username
            </label>
            |
            <input
              type="text"
              name="username"
              id="username"
              placeholder="Enter your username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>
          <div className="register__form-group">
            <label className="register__form-label" htmlFor="password">
              Password
            </label>
            |
            <input
              type="password"
              name="password"
              id="password"
              placeholder="Enter your password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <div className="register__form-group">
            <label className="register__form-label" htmlFor="email">
              Email
            </label>
            |
            <input
              type="email"
              name="email"
              id="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="register__form-group">
            <label className="register__form-label" htmlFor="phone">
              Phone
            </label>
            |
            <input
              type="text"
              name="phone"
              id="phone"
              placeholder="Enter your phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              required
            />
          </div>
          <button
            type="submit"
            className="register__form-button"
            disabled={loading}
          >
            {loading ? "Registering..." : "Register"}
          </button>
        </form>
      </div>
    </div>
  );
};
