import { useState } from "react";

// import "../styles/ConfirmBroker.css";

import { Notification, notify } from "../components/Notification";
import { NuvamaLogin } from "../apis/TradeMasterApi";
import { useParams, useSearchParams } from "react-router-dom";

export const ConfirmBroker = () => {
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const { broker } = useParams();
  const apiKey = localStorage.getItem("apiKey");
  const apiSecret = localStorage.getItem("apiSecret");

  const handleConfirmBroker = async () => {
    setLoading(true);
    try {
      const response = await NuvamaLogin(apiKey, apiSecret, searchParams.get("request_id"));
      notify("success", response.message);
      localStorage.setItem("accessToken", response.data.access_token);
      localStorage.setItem("vendorToken", response.data.vendor_token);
      localStorage.setItem("isBrokerConnected", response.data.access_token);
      window.location.href = "/dashboard";
      setLoading(false);
    } catch (error) {
      notify("error", error.response.data.message);
      setLoading(false);
      window.location.href = "/dashboard";
    }
  };

  return (
    <div className="confirmBroker">
      <Notification />
      <div className="confirmBroker__content">
        <div className="confirmBroker__title">
          <h1>Confirm Broker</h1>
        </div>
        <div className="confirmBroker__description">
          <p>
            You are about to connect your {broker} account with Nuvama. Please
            confirm to proceed.
          </p>
        </div>
        <div className="confirmBroker__button">
          <button
            className="confirmBroker__button_confirm"
            onClick={handleConfirmBroker}
          >
            {loading ? "Connecting..." : "Confirm"}
          </button>
        </div>
      </div>
    </div>
  );
};
