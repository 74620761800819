import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { UserResetPassword } from "../apis/TradeMasterApi";
import { notify, Notification } from "../components/Notification";
import Logo from "../assets/logos/Logo.png";
import "../styles/Register.css";

export const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await UserResetPassword(token, password);
      notify("success", response.message);
      setSuccess(true);
      setLoading(false);
    } catch (error) {
      notify("error", error.response.data.message);
      setSuccess(false);
      setLoading(false);
    }
  };

  return (
    <div className="register">
      <Notification />
      <div className="register__content">
        <div className="register__logo">
          <img src={Logo} alt="logo" />
        </div>
        <form className="register__form" onSubmit={handleResetPassword}>
          <div className="register__form-group">
            <label className="register__form-label" htmlFor="password">
              Password
            </label>
            |
            <input
              type="password"
              name="password"
              id="password"
              placeholder="Enter your password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button
            type="submit"
            className="register__form-button"
            disabled={loading}
          >
            {loading ? "Loading..." : "Submit"}
          </button>
          <p className="register__form-text">
            {success ? (
              <div className="register__form-message-success">
                <div className="register__form-message-success__link">
                  <a href="/login">Login</a>
                </div>
              </div>
            ) : (
              <div className="register__form-message-error">
                <div className="register__form-message-error__link">
                  <a href="/forgot/password">Forgot Password</a>
                </div>
              </div>
            )}
          </p>
        </form>
      </div>
    </div>
  );
};
