import toast, { Toaster } from "react-hot-toast";
import "../styles/Notification.css"

export const notify = (type, message) =>{
  if(type === "success"){
    toast.success(message);
  }else if(type === "error"){
    toast.error(message);
  }else if(type === "loading"){
    toast.loading(message);
  }else if(type === "notify"){
    toast(message);
  }
}

export const Notification = () => {
  return (
    <div>
      <Toaster
        position="top-center"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          // Define default options
          className: "notification",
          duration: 1800,
          style: {
          },

          // Default options for specific types
          success: {
            duration: 3000,
            theme: {
              primary: "green",
              secondary: "black",
            },
          },

          error: {
            duration: 4000,
            theme: {
              primary: "green",
              secondary: "black",
            },
          },
        }}
      />
    </div>
  );
};
