import React, { Fragment } from "react";
import { Navigate } from "react-router-dom";

export const ProtectedRoute = ({ component: Component, ...routeProps }) => {
  const isAuthenticated = localStorage.getItem("isAuthenticated");

  if (isAuthenticated) {
    return (
      <Fragment>
        <Component {...routeProps} />
      </Fragment>
    );
  } else {
    return <Navigate to="/login" />;
  }

  
};
