import "../styles/DashBoard.css";
import Logo from "../assets/logos/Logo.png";
import { Strategies } from "../components/Strategies";
import { FaWallet } from "react-icons/fa";
import { RiLogoutCircleLine } from "react-icons/ri";
import { IoAddCircleOutline } from "react-icons/io5";
import { useEffect, useState } from "react";
import { SetupDemat } from "../components/SetupDemat";
import { Loader } from "../components/Loader";
import {
  GetDematAccount,
  GetAPI,
  NuvamaMargin,
  CheckLogin,
  GetUser,
  WisdomCapitalMargin,
  WisdomCapitalLogin
} from "../apis/TradeMasterApi";
import { notify, Notification } from "../components/Notification";
import { AvailableBrokers } from "../constants/AvailableBrokers";

export const DashBoard = () => {
  const [isSetupBroker, setIsSetupBroker] = useState(false);
  const isAuthenticated = localStorage.getItem("isAuthenticated");
  const [username, setUsername] = useState("");
  const [clientID, setClientID] = useState("");
  const [broker, setBroker] = useState("");
  const [apiKey, setAPIKey] = useState(localStorage.getItem("apiKey"));
  const [apiSecret, setAPISecret] = useState(localStorage.getItem("apiSecret"));
  const [vendorToken, setVendorToken] = useState(
    localStorage.getItem("vedorToken")
  );
  const [accessToken, setAccessToken] = useState(
    localStorage.getItem("accessToken")
  );
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  console.log(vendorToken);
  console.log(accessToken);
  const [marginAvailable, setMarginAvailable] = useState(0.0);
  const [isBrokerConnected, setIsBrokerConnected] = useState(
    localStorage.getItem("isBrokerConnected")
  );

  if (!isAuthenticated) {
    window.location.href = "/login";
  }

  useEffect(() => {
    async function fetchData() {
      try {
        await CheckLogin().then((response) => {
          if (response.message !== "Success") {
            localStorage.clear();
            window.location.href = "/login";
          }
        });

        await GetUser().then((response) => {
          setUsername(response.username);
        });

        await GetDematAccount().then((response) => {
          console.log(response);
          setClientID(response.client_id);
          setBroker(response.broker);
          try {
            GetAPI(response.client_id, response.broker).then((apiResponse) => {
              console.log(apiResponse);
              if (
                new Date(apiResponse.access_token_expiry).getTime() < Date.now()
              ) {
                console.log(isBrokerConnected);
                if (isBrokerConnected === "true") {
                  notify("error", "Broker Login Expired, Please Login Again");
                }
                setIsBrokerConnected(false);
                localStorage.setItem("isBrokerConnected", false);
                console.log(apiResponse);
                setAPIKey(apiResponse.api_key);
                setAPISecret(apiResponse.api_secret);
                setVendorToken(apiResponse.vendor_token);
                setAccessToken(apiResponse.access_token);
              } else {
                if (response.broker === "Nuvama") {
                  NuvamaMargin(
                    response.client_id,
                    apiResponse.api_key,
                    apiResponse.vendor_token,
                    apiResponse.access_token
                  ).then((marginResponse) => {
                    setMarginAvailable(marginResponse.data.margin_available);
                    setIsBrokerConnected(true);
                  });
                }
                if (response.broker === "WisdomCapital")
                {
                  WisdomCapitalMargin(
                    response.client_id
                  ).then((marginResponse) => {
                    setMarginAvailable(marginResponse.data);
                    setIsBrokerConnected(true);
                  });
                }
              }
              localStorage.setItem("apiKey", apiResponse.api_key);
              localStorage.setItem("apiSecret", apiResponse.api_secret);
            });
          } catch (error) {
            notify("error", error.response.data.message);
            setIsBrokerConnected(false);
          }
        });
      } catch (error) {
        if (error.response.status === 422) {
          localStorage.clear();
          window.location.href = "/login";
          notify("error", "Login Expired, Please Login Again");
        } else {
          
          // notify("error", error.response.data.message);
        }
      }
      setIsDataLoaded(true);
    }
    fetchData();
  }, [isBrokerConnected]);

  const handleLogout = () => {
    localStorage.clear();
    window.location.href = "/login";
  };

  const handleSetupBroker = () => {
    setIsSetupBroker(true);
  };

  const handleBrokerLogin = async () => {
    if (isBrokerConnected) {
      notify("success", "Broker already connected");
    } else {
      if (broker === "WisdomCapital")
      {
        try{
          await WisdomCapitalLogin(apiKey,apiSecret).then((response) => {
            console.log(response.data.access_token);
            setAccessToken(response.data.access_token);
            setIsBrokerConnected(true);
          });
        }
        catch(error)
        {
          notify("error", error.respon.data.message);
        }
        
      }
      else{
      window.location.href =
        AvailableBrokers.find((item) => item.name === broker).loginUrl + apiKey;
      }
    }
  };

  if (!isDataLoaded) {
    return <Loader />;
  } else {
    return (
      <div className="dashboard">
        <Notification />
        <div className="dashboard__header">
          <div className="dashboard__header__left">
            <div className="dashboard__header__logo">
              <img src={Logo} alt="logo" />
            </div>
          </div>
          <div>
            {username ? (
              <div className="dashboard__header__center">
                <div className="dashboard__header__center__username">
                  Welcome,
                  <span className="dashboard__header__center__username__text">
                    {username.toLocaleUpperCase()}
                  </span>
                </div>
              </div>
            ) : null}
          </div>
          <div className="dashboard__header__right">
            <div className="dashboard__header__right__profile">
              <button
                className="dashboard__header__right__logout__button"
                onClick={handleLogout}
              >
                <RiLogoutCircleLine />
              </button>
            </div>
          </div>
        </div>
        <div className="dashboard__body">
          <div className="dashboard__body__broker">
            <div className="dashboard__body__broker__status">
              {/* <div className="dashboard__body__broker__status__icon">
              <FaCircle />
            </div> */}
              {broker ? (
                <div
                  className="dashboard__body__broker__status__text-added"
                  onClick={handleSetupBroker}
                >
                  <div className="dashboard__body__broker__status__text__icon">
                    <img
                      src={
                        AvailableBrokers.find((item) => item.name === broker)
                          .icon
                      }
                      alt="logo"
                    />
                  </div>
                  <span className="broker__name">{clientID}</span>
                </div>
              ) : (
                <div
                  className="dashboard__body__broker__status__text"
                  onClick={handleSetupBroker}
                >
                  NO BROKER SETUP{" "}
                  <span className="add__broker_button">
                    {" "}
                    <IoAddCircleOutline />{" "}
                  </span>
                </div>
              )}
              <button
                className="dashboard__body__broker__status__button"
                onClick={handleBrokerLogin}
              >
                {isBrokerConnected ? "CONNECTED" : "CONNECT"}
              </button>
            </div>
            <div className="dashboard__body__broker__balance">
              <div className="dashboard__body__broker__balance__icon">
                <FaWallet />
              </div>
              <div className="dashboard__body__broker__balance__text">
                {marginAvailable}
              </div>
            </div>
          </div>
          <div className="dashboard__body__strategies">
            <Strategies client_id={clientID} broker_name={broker} />
          </div>
        </div>
        {isSetupBroker && (
          <SetupDemat
            setIsSetupBroker={setIsSetupBroker}
            addedClientID={clientID}
            addedBroker={AvailableBrokers.find((item) => item.name === broker)}
            addedApiKey={apiKey}
            addedApiSecret={apiSecret}
          />
        )}
      </div>
    );
  }
};
