import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Landing } from "./pages/Landing";
import { Login } from "./pages/Login";
import { NotFound } from "./pages/NotFound";
import { DashBoard } from "./pages/DashBoard";
import { Register } from "./pages/Register";
import { RegisterConfirm } from "./pages/RegisterConfirm";
import { ForgotPassword } from "./pages/ForgotPassword";
import { ResetPassword } from "./pages/ResetPassword";
import { ConfirmBroker } from "./pages/ConfirmBroker";
import { ProtectedRoute } from "./components/ProtectedRoute";
import { PrivacyPolicy } from "./pages/PrivacyPolicy";
import { TAC } from "./pages/TAC";

function App() {
  return (
    <div className="app">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Landing />} exact />
          <Route
            exact
            path="/dashboard"
            element={<ProtectedRoute component={DashBoard} />}
          />
          <Route path="/confirm_broker/:broker" element={<ConfirmBroker />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/register/confirm/" element={<RegisterConfirm />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-and-conditions" element={<TAC />} />
          <Route path="/forgot/password" element={<ForgotPassword />} />
          <Route path="/reset/password" element={<ResetPassword />} />
          <Route path="/*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
      <div className="copyright">
        Copyrights © Trade Master All Rights Reserved
      </div>
    </div>
  );
}

export default App;
