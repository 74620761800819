import MotilalIcon from "../assets/Motilal-200x200.png";
import NuvamaIcon from "../assets/Nuvama-200x200.png";
import WisdomCapitalIcon from "../assets/WisdomCapital-200x200.png"

export const AvailableBrokers = [
    {
        name: "Motilal",
        icon: MotilalIcon,
        loginUrl: "https://invest.motilaloswal.com/OpenAPI/Login.aspx?apikey=",
    },
    {
        name: "Nuvama",
        icon: NuvamaIcon,
        loginUrl: "https://www.edelweiss.in/api-connect/login?api_key=",
    },
    {
        name: "WisdomCapital",
        icon: WisdomCapitalIcon,
        loginUrl: ""
    }
];