import "../styles/Hero.css";

import {
  FaFacebookSquare,
  FaInstagramSquare,
  FaTwitterSquare,
  FaLinkedin,
} from "react-icons/fa";
import Logo from "../assets/logos/Logo.png";

export const Hero = () => {
  const handleGetStarted = () => {
    window.location.href = "/register";
  };

  const handleLogin = () => {
    window.location.href = "/login";
  };

  return (
    <div className="hero">
      <div className="hero__content">
        <div className="hero__logo">
          <img src={Logo} alt="logo" />
        </div>
        <h1 className="hero__title">
          Hello, I'm{" "}
          <span className="hero__title--highlight">Trade Master</span>
        </h1>
        <h2 className="hero__subtitle">
          <span>
            I can maximize your trading potential with{" "}
            <span className="hero__subtitle--highlight-green">
              cutting-edge strategies
            </span>
          </span>
          <span>
            and fully automated
            <span className="hero__subtitle--highlight-green">
              {" "}
              trading robots,{" "}
            </span>{" "}
            Say good bye to{" "}
            <span className="hero__subtitle--highlight-red">
              {" "}
              manual trading!
            </span>
          </span>
        </h2>
        <div className="hero__interactive">
          <button
            className="hero__button-get-started"
            onClick={handleGetStarted}
          >
            Get Started
          </button>
          <button className="hero__button-login" onClick={handleLogin}>
            Login
          </button>
        </div>
      </div>
      <div className="footer">
        <div className="footer__social">
          <h3 className="footer__social--title">Contact us:</h3>
          
          <div className="footer_social__list">
            <a
              href="https://www.facebook.com/profile.php?id=100092969667052"
              target="_blank"
              rel="noreferrer"
            >
              <FaFacebookSquare className="footer__social--icon" />
            </a>
            <a
              href="https://instagram.com/trademaster.live?igshid=MzNlNGNkZWQ4Mg==
              "
            >
              <FaInstagramSquare className="footer__social--icon" />
            </a>
            <a
              href="https://twitter.com/LiveTrademaster?t=HlRPgSbMY1d4czVyCPBOiw&s=09"
            >
              <FaTwitterSquare className="footer__social--icon" />
            </a>
            <a
              href="https://www.linkedin.com/company/trademaster-live"
            >
              <FaLinkedin className="footer__social--icon" />
            </a>
          </div>
          <div className="footer__social--email">Email: 
            <a href="mailto:customerservice@trademaster.live">
            customerservice@trademaster.live
            </a>
          </div>
        </div>
        <div className="footer__policy">
          <a href="/privacy-policy" className="footer__policy--link">
            Privacy Policy
          </a>
          <a href="/terms-and-conditions" className="footer__policy--link">
            Terms of Service
          </a>
        </div>
      </div>
    </div>
  );
};
